<template>
  <div>
    <b-modal
      v-model="showModal"
      ok-only
      ok-title="Accept"
      centered
      size="xl"
      :hide-footer="true"
      :title="boutique.country"
      lazy
      static
    >
      <div v-if="boutique && boutique.data && boutique.data.products">
        <b-row class="match-height mb-2">
          <b-col :md="12">
            <span class="mb-2">Choisissez la liste des produits disponibles en boutique</span>
            <v-select
              v-model="boutique.data.products"
              placeholder="Produit"
              label="name"
              :options="productSelect"
              :reduce="product => product.id"
              :clearable="false"
              :multiple="true"
              class="invoice-filter-select"
              transition=""
            />
          </b-col>
        </b-row>
        <b-row
          class="my-2"
        >
          <b-col
            span="12"
            class="primary d-flex justify-content-end"
          >
            <b-button
              variant="primary"
              @click="updateValue()"
            >
              <span class="text-nowrap">Modifier</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-row class="match-height">
      <b-col
        cols="12"
      >
        <p class="mb-2">
          <span>Permet d'indiquer quel produit est disponible dans chaque boutique.</span>
        </p>
      </b-col>
      <b-card class="col-12" title="Vous pouvez modifier les produits directement ici.">
        <b-row class="match-height">
          <b-col
            v-for="(boutique, key) in boutiques"
            :key="key"
            md="6"
            lg="6"
            class=""
          >
            <b-card
              class="ecommerce-card"
              no-body
            >
              <div
                class="item-img text-center"
              >
                <b-img
                  fluid
                  class="card-img-top"
                  style="height: 250px; object-fit: cover"
                  :src="boutique.country === 'lyon' ? 'https://s3.eu-west-3.amazonaws.com/cdn.kipli/home/boutique-lyon.jpg' : boutique.country === 'paris' ? 'https://res.cloudinary.com/kipli-cdn/image/upload/f_webp,q_auto/site/boutique/paris-boutique' : 'https://res.cloudinary.com/kipli-cdn/image/upload/f_webp,q_auto/site/boutique/toulouse'"
                />
              </div>

              <!-- boutique Details -->
              <b-card-body class="text-center">
                <h4 class="item-name">
                  {{ boutique.country.toUpperCase() }}
                </h4>
                <!-- <b-card-text class="item-description">
                  {{ product.short_description }}
                </b-card-text> -->
              </b-card-body>

              <!-- Product Actions -->
              <div class="item-options text-center">
                <b-button
                  variant="flat-primary"
                  tag="a"
                  class="btn-cart mb-1"
                  @click="openModal(boutique)"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                  />
                  <span>Modifier les produits</span>
                </b-button>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-card>
    </b-row>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import {
  BCard,
  BModal,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BButton,
  // BFormInput,
} from 'bootstrap-vue'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BModal,
    BRow,
    BCol,
    BImg,
    BButton,
    BCardBody,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,
  },
  data() {
    return {
      showModal: false,
      boutiques: [],
      boutique: {},
      productSelect: [],
    }
  },
  async mounted() {
    try {
      const { boutiques } = await this.$http.get('/admin/boutique/site')
      this.boutiques = boutiques
      const { products } = await this.$http.get('/admin/blog/list-product/?country=FR')
      this.productSelect = products
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    openModal(boutique) {
      this.showModal = true
      this.boutique = boutique
      this.boutique.data.products = boutique.data.products.filter(p => p)
    },
    async updateValue() {
      try {
        await this.$http.put(`/admin/boutique/site/${this.boutique._id}`, { data: this.boutique.data })
        const { boutiques } = await this.$http.get('/admin/boutique/site')
        this.boutiques = boutiques
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Enregistrement réussi',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.showModal = false
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
